import NetService from './net-service'
import ConfigService from './config-service'
import Vue from 'vue'

let servicesInstance
export default class Services {
  constructor (store) {
    this.services = {}

    this.register('net', function () {
      return new NetService()
    })
    this.register('config', function () {
      return new ConfigService()
    })
    this.register('eventHub', function () {
      return new Vue()
    })
  }

  static instance () {
    if (servicesInstance === undefined) {
      servicesInstance = new Services()
    }
    return servicesInstance
  }

  static createInstance () {
    if (servicesInstance) servicesInstance.services = null
    servicesInstance = new Services()
    return servicesInstance
  }

  /**
     *
     * @param {String} name
     * @param {function(Services)} cb
     */
  register (name, cb) {
    this.services[name] = {
      cb: cb,
      instance: undefined
    }
  }

  /**
     * Получить сервис.
     * @param {String} name
     * @returns {*}
     */
  get (name) {
    if (this.services[name] === undefined) {
      throw new Error(`Service '${name}' is not registered.`)
    }

    if (this.services[name].instance === undefined) {
      this.services[name].instance = this.services[name].cb(this)
    }
    return this.services[name].instance
  }

  /**
     * @return {NetService}
     */
  static net () {
    return Services.instance().get('net')
  }

  /**
     * @return {ConfigService}
     */
  static config () {
    return Services.instance().get('config')
  }

  /**
     * @return {Vue}
     */
  static eventHub () {
    return Services.instance().get('eventHub')
  }

  /**
     * @return {BaseCookie}
     */
  static cookie () {
    return Services.instance().get('cookie')
  }

  static store () {
    return Services.instance().get('store')
  }
}
