import Services from './services'

export default class Config {
  constructor () {
    this.values = Services.store().state.env
  }

  set (values) {
    this.values = values
  }

  get () {
    return this.values
  }
}
