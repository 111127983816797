/* eslint-disable prefer-promise-reject-errors */
import Services from './services'
import axios from 'axios'

export default class NetService {
  /**
     * Осуществляет POST-запрос к бэк-микросервису.
     *
     * @param {String} backend
     * @param {String} path
     * @param {*} data
     * @param {Boolean} hasFile - Запрос с передачей файла?
     * @returns {Promise<Object>}
     */
  post (backend, path, data, hasFile = false) {
    const backendData = Services.config().get().api[backend]
    const store = Services.store()

    if (backendData === undefined) {
      throw new Error(`Undefined backend '${backend}'.`)
    }

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    if (backendData.auth) {
      headers.Authorization = backendData.auth
    }

    if (process.env.VUE_ENV === 'server') {
      headers.origin = Services.config().get().hostname
    }

    const params = {
      data: hasFile ? data : JSON.stringify(data),
      method: 'POST',
      mode: 'cors',
      headers: headers
    }

    const url = backendData.host + path

    store.commit('preloader_up')

    return axios(url, params).then(
      function (response) {
        store.commit('preloader_down')
        return response.data
      },
      function (response) {
        store.commit('preloader_down')

        if (process.env.VUE_ENV === 'server') {
          if (response.response && response.response.data && response.response.data.errors) {
            console.log(response.response.data.errors)
          } else if (response.response && response.response.data) {
            console.log(response.response.data)
          } else if (response.response) {
            console.log(response.response)
          } else {
            console.log(response)
          }
        } else {
          alert(response.response.data.errors)
        }

        return Promise.reject()
      }
    )
  }
}
