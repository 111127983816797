import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'

import Regex from './regex'
import Helpers from './helpers'

Vue.use(VeeValidate)

const dictionary = {
  ru: {
    messages: {
      required: () => 'Обязательное поле',
      email: () => 'Формат электронной почты: name@domain.сom',
      confirmed: () => 'Поле не совпадает',
      size: (field, [size]) => `Файл должен весить не более, чем ${size / 1024} Mb`,
      max: (field, [length]) => `Это значение должно содержать не более ${length} символов`,
      min: (field, [length]) => `Это значение должно содержать не менее ${length} символов`,
      min_value: (field, [length]) => `Значение должно быть не менее ${length}`
    }
  }
}

Validator.localize('ru', dictionary.ru)

Validator.extend('name', {
  getMessage: () => 'Только латинские и русские буквы, тире и пробелы',
  validate: value => Regex.nameAll.test(value)
})

Validator.extend('nameRu', {
  getMessage: () => 'Только русские буквы, тире и пробелы',
  validate: value => Regex.nameRu.test(value)
})

Validator.extend('card', {
  getMessage: () => 'Карта должна содержать 16 цифр',
  validate: value => Regex.card.test(value)
})

Validator.extend('nameEn', {
  getMessage: () => 'Только латинские буквы, тире и пробелы',
  validate: value => Regex.nameEn.test(value)
})

Validator.extend('password', {
  getMessage: () => 'Пароль должен содержать как минимум 1 заглавную и строчную латинские буквы и 1 цифру',
  validate: value => Regex.password.test(value)
})

Validator.extend('tel', {
  getMessage: () => 'Формат телефонного номера: +7(000) 000-00-00',
  validate: value => Regex.tel.test(value)
})

Validator.extend('kpp', {
  getMessage: () => 'Некорректный формат КПП',
  validate: value => Regex.kpp.test(value)
})

Validator.extend('inn', {
  getMessage: () => 'Некорректный формат ИНН',
  validate: value => {
    const inn = value

    let isCorrect = false
    let checkdigit
    let firstCheckdigit
    let secondCheckdigit

    /* ИНН может быть 10 или 12-значным и в каждом случае имеет свою логику проверки */
    switch (inn.length) {
      case 10:
        checkdigit = Helpers.countCheckdigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
        if (checkdigit === parseInt(inn[9], 10)) {
          isCorrect = true
        }
        break
      case 12:
        firstCheckdigit = Helpers.countCheckdigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
        secondCheckdigit = Helpers.countCheckdigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
        if (firstCheckdigit === parseInt(inn[10], 10) && secondCheckdigit === parseInt(inn[11], 10)) {
          isCorrect = true
        }
        break
      default:
    }

    return isCorrect
  }
})

Validator.extend('minLength', {
  getMessage (field, [length]) {
    return `Выберите не менее ${length} вариантов.`
  },
  validate (value, [length]) {
    return value.length >= +length
  }
})

Validator.extend('maxLength', {
  getMessage (field, [length]) {
    return `Выберите не более ${length} вариантов.`
  },
  validate (value, [length]) {
    const l = +length
    return value.length <= l
  }
})

Validator.extend('birthdate18', {
  getMessage: () => 'Вы должны быть старше 16 лет',
  validate: value => {
    const birthdate = value
    if (birthdate === '') {
      return false
    }
    const diff = ((new Date()).getTime() - (new Date(birthdate)).getTime()) / (1000 * 60 * 60 * 24 * 365)

    return diff.toFixed() >= 16
  }
})

Validator.extend('checkbox', {
  getMessage: () => 'Обязательное поле!',
  validate: value => value === true
})
