/* eslint-disable no-prototype-builtins */
/**
 * @module Helpers
 */

import Services from '../services/services.js'

/**
 * Класс, содержащий набор статичных вспомогательных функций.
 * </pre>
 */
export default class Helpers {
  /**
     * Создаёт массив из всех целый чисел от start до end.
     *
     * @param  {number} start Первое значение.
     * @param  {number} end   Последнее значение.
     * @return {number[]}        Массив значений.
     */
  static range (start, end) {
    return Array(end - start + 1)
      .fill()
      .map((item, index) => start + index)
  }

  /**
     * Переводит переменную даты в строку.
     *
     * @param  {Date}   date Объект даты.
     * @return {string}      Строка даты.
     */
  static dateToString (date) {
    const day = `0${date.getDate()}`.slice(-2)
    const month = `0${date.getMonth() + 1}`.slice(-2)
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
  }

  /**
     * Проверка на touch device.
     *
     * @return {boolean} Результат проверки.
     */
  static isTouch () {
    return 'ontouchstart' in window
  }

  /**
     * Превращает значение любого типа в массив.
     *
     * @param {*} arg Аргумент любого типа.
     */
  static toArray (arg) {
    return [].concat(...[arg])
  }

  /**
     * Вычисляет контрольное число.
     *
     * @param  {string}   str          Исходная строка.
     * @param  {number[]} coefficients Коэффициенты контрольной суммы.
     * @return {number}                Контрольное число.
     */
  static countCheckdigit (str, coefficients) {
    const checksum = coefficients.reduce((sum, coefficient, index) => sum + coefficient * str[index], 0)

    return (checksum % 11) % 10
  }

  /**
     * Получить полную ссылку на сайт для внешнего использвоания
     * @param url
     * @param urlEncode
     * @return string
     */
  static getExternalUrl (url, urlEncode = true) {
    if (url[0] === '/') {
      url = url.substr(1)
    }

    let result = Services.config().get().hostname + url

    if (urlEncode) {
      result = encodeURIComponent(result)
    }

    return result
  }

  static transliterate (text) {
    const ru = ['Я', 'я', 'Ю', 'ю', 'Ч', 'ч', 'Ш', 'ш', 'Щ', 'щ', 'Ж', 'ж', 'А', 'а', 'Б', 'б', 'В', 'в', 'Г', 'г', 'Д', 'д', 'Е', 'е', 'Ё', 'ё', 'З', 'з', 'И', 'и', 'Й', 'й', 'К', 'к', 'Л', 'л', 'М', 'м', 'Н', 'н', 'О', 'о', 'П', 'п', 'Р', 'р', 'С', 'с', 'Т', 'т', 'У', 'у', 'Ф', 'ф', 'Х', 'х', 'Ц', 'ц', 'Ы', 'ы', 'Ь', 'ь', 'Ъ', 'ъ', 'Э', 'э', ' ']
    const en = ['Ya', 'ya', 'Yu', 'yu', 'Ch', 'ch', 'Sh', 'sh', 'Sh', 'sh', 'Zh', 'zh', 'A', 'a', 'B', 'b', 'V', 'v', 'G', 'g', 'D', 'd', 'E', 'e', 'E', 'e', 'Z', 'z', 'I', 'i', 'J', 'j', 'K', 'k', 'L', 'l', 'M', 'm', 'N', 'n', 'O', 'o', 'P', 'p', 'R', 'r', 'S', 's', 'T', 't', 'U', 'u', 'F', 'f', 'H', 'h', 'C', 'c', 'Y', 'y', '', '', '', '', 'E', 'e', '_']

    for (let i = 0; i < ru.length; i++) {
      const reg = new RegExp(ru[i], 'g')
      text = text.replace(reg, en[i])
    }
    return text
  }

  /**
     * Выбор нужной формы слова
     *
     *   let forms = [
     *     "банан",
     *     "банана",
     *     "бананов"
     *   ];
     *
     *   plural_form(1, forms); //банан
     *   plural_form(2, forms); //банана
     *   plural_form(5, forms); //бананов
     *
     * @param n
     * @param forms
     * @return string
     */
  static pluralForm (n, forms) {
    return n % 10 === 1 && n % 100 !== 11
      ? forms[0]
      : (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? forms[1] : forms[2])
  }

  /**
     * Проверяет значение на наличие в массиве.
     *
     * @param  {*}       value Значение.
     * @param  {*}       arr   Массив.
     * @return {boolean}       Результат проверки.
     */
  static inArray (value, arr) {
    return Array.isArray(arr) && arr.includes(value)
  }

  static showOverlay () {
    document.body.classList.add('has-overlay')
  }

  static hideOverlay () {
    document.body.classList.remove('has-overlay')
  }

  static getVacanciesFilterFromRoute (route, store) {
    let filter = []

    if (typeof route.params.filter === 'undefined') {
      filter = [store.state.city.selectedCity.code, 'map']
    } else if (Array.isArray(route.params.filter)) {
      filter = route.params.filter
    } else {
      filter = route.params.filter.split('/')
    }

    filter = filter.filter((elem, pos, arr) => {
      return elem && arr.indexOf(elem) === pos
    })

    const cityCode = filter.shift()
    const mode = filter.shift()
    const parsedFilters = {}

    filter.filter(function (f) {
      return f !== ''
    }).forEach(function (element) {
      const pos = element.search('-')
      const fName = element.substring(0, pos)

      parsedFilters[fName] = element.substring(pos + 1)
    })

    const filterResult = {}

    // ========== Проверяем тип вакансии ==========
    if (parsedFilters.hasOwnProperty('type')) {
      const vacancyType = store.state.filter.types.find(type => {
        return type.code === parsedFilters.type
      })

      if (vacancyType) {
        filterResult.vacancyType = vacancyType.code
      }
    }

    if (!filterResult.vacancyType) {
      filterResult.vacancyType = store.state.filter.types[0].code
    }

    // ========== Проверяем должность ==========
    if (parsedFilters.hasOwnProperty('position')) {
      const position = store.state.filter.positions.find(position => {
        return position.code === parsedFilters.position
      })

      if (position) {
        filterResult.position = position.code
      }
    }

    if (!filterResult.position) {
      filterResult.position = 0
    }

    // ========== Проверяем график работ ==========
    if (parsedFilters.hasOwnProperty('timetable')) {
      const timetable = store.state.filter.timetables.find(timetable => {
        return timetable.code === parsedFilters.timetable
      })

      if (timetable) {
        filterResult.timetable = timetable.code
      }
    }

    if (!filterResult.timetable) {
      filterResult.timetable = 0
    }

    // ========== Проверяем метро ==========
    filterResult.metro = parsedFilters.hasOwnProperty('metro') ? +parsedFilters.metro : 0

    // ========== Проверяем сферы деятельности ==========
    if (parsedFilters.hasOwnProperty('directions')) {
      const selectedDirectionsArray = parsedFilters.directions.split(',')
      const directions = store.state.filter.directions.filter(direction => {
        return Helpers.inArray(direction.code, selectedDirectionsArray)
      })

      if (directions.length > 0) {
        filterResult.directions = directions.map(v => v.code)
      }
    }

    if (!filterResult.directions) {
      filterResult.directions = []
    }

    // ========== Проверяем теги ==========
    if (parsedFilters.hasOwnProperty('tags')) {
      const selectedTagsArray = parsedFilters.tags.split(',')
      const tags = store.state.filter.tags.filter(tag => {
        return Helpers.inArray(tag.code, selectedTagsArray)
      })

      if (tags.length > 0) {
        filterResult.tags = tags.map(v => v.code)
      }
    }

    if (!filterResult.tags) {
      filterResult.tags = []
    }

    // ========== Проверяем видимую область карты ==========
    if (parsedFilters.hasOwnProperty('bounds')) {
      const bounds = parsedFilters.bounds.split(',')
      filterResult.bounds = [[Number(bounds[0]), Number(bounds[1])], [Number(bounds[2]), Number(bounds[3])]]
    } else {
      filterResult.bounds = false
    }

    // ========== Проверяем показывать ли магазины без вакансий ==========
    filterResult.show_without_vacancies = parsedFilters.hasOwnProperty('showWithoutVacancies')

    return {
      cityCode: cityCode,
      mode: mode,
      filter: filterResult
    }
  }

  static getCenter (bounds) {
    return [(Number(bounds[0][0]) + Number(bounds[1][0])) / 2, (Number(bounds[0][1]) + Number(bounds[1][1])) / 2]
  }

  static rad (x) {
    return x * Math.PI / 180
  }

  static getDistance (p1, p2) {
    const R = 6378137 // Earth’s mean radius in meter
    const dLat = Helpers.rad(Number(p2[0]) - Number(p1[0]))
    const dLong = Helpers.rad(Number(p2[1]) - Number(p1[1]))
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(Helpers.rad(Number(p1[0]))) * Math.cos(Helpers.rad(Number(p2[0]))) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c // returns the distance in meter
  }

  static areIntersecting (rect1, rect2) {
    const interByW = Helpers.intersectProjections(Helpers.projection(rect1, 0), Helpers.projection(rect2, 0))
    const interByH = Helpers.intersectProjections(Helpers.projection(rect1, 1), Helpers.projection(rect2, 1))

    return interByW && interByH
  }

  static intersectProjections (proj1, proj2) {
    const [w11, w12] = proj1
    const [w21, w22] = proj2

    return (w12 >= w21 && w11 <= w21) || (w22 >= w11 && w21 <= w11)
  }

  static projection (rect, dir) {
    return [rect[0][dir], rect[1][dir]].sort()
  }

  static getCareerPositions (items, salaries) {
    items.forEach((element) => {
      if (!element.salary) {
        const salary = salaries.find((v) => {
          return element.name.replace('<br />', '') === v.name
        })

        if (salary) {
          element.salary = Helpers.salaryDesc(salary.salary_min, salary.salary_max)
        }
      }
    })

    return items
  }

  static salaryText (salary) {
    return (salary && Number(salary) > 0) ? Number(salary).toString() : ''
  }

  static salaryDesc (salaryMin, salaryMax) {
    let text = ''
    const salaryMaxText = Helpers.salaryText(salaryMax)
    const salaryMinText = Helpers.salaryText(salaryMin)

    if (salaryMaxText && salaryMinText) {
      text = `от ${salaryMinText} до ${salaryMaxText}`
    } else if (salaryMaxText) {
      text = `до ${salaryMaxText}`
    } else if (salaryMinText) {
      text = `от ${salaryMinText}`
    }

    if (text) {
      text += ' ₽'
    } else {
      text = 'з/п не указана'
    }

    return text
  }
}
