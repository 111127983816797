<template>
  <div>
    <!-- Основной блок с текстом и белыми точками -->
    <div class="intro-section">
      <div class="dots"></div>
      <p>Зарабатывай в команде с «Перекрёстком»!</p>
      <p>
        Выбирай из широкого спектра вакансий <br class="desktop-br" /> в супермаркетах, распределительных
        центрах, доставке и кафе.
      </p>
      <p>
        Подберём график, который подходит именно тебе, и позаботимся об обучении
        и достойной оплате.
      </p>
    </div>

    <!-- Блок с иконками преимуществ -->
    <div class="benefits-icons">
      <div class="benefit-item">
        <img
          src="https://rabota.perekrestok.ru/takedollarincash.svg"
          alt="Иконка регулярные выплаты"
        />
        <p>Регулярные <br />выплаты</p>
      </div>
      <div class="benefit-item">
        <img
          src="https://rabota.perekrestok.ru/location.svg"
          alt="Иконка удобного места работы"
        />
        <p>Удобное место <br />и график работы</p>
      </div>
      <div class="benefit-item">
        <img
          src="https://rabota.perekrestok.ru/Lider_table.svg"
          alt="Иконка обучения"
        />
        <p>
          Профессиональное <br />обучение <br />
          и карьерное развитие
        </p>
      </div>
      <div class="benefit-item">
        <img
          src="https://rabota.perekrestok.ru/gift_card.svg"
          alt="Иконка премий"
        />
        <p>
          Ежемесячные премии <br />
          и компенсация питания
        </p>
      </div>
      <div class="benefit-item">
        <img
          src="https://rabota.perekrestok.ru/salesandbonuses.svg"
          alt="Иконка скидок"
        />
        <p>Корпоративные <br />скидки и бонусы</p>
      </div>
    </div>

    <!-- Блок с логотипом Перекрестка и виджетом -->
    <div class="footer-section">
      <div class="logo-containers">
        <img
          src="https://rabota.perekrestok.ru/logo_black.svg"
          alt="Логотип Перекрёстка"
        />
      </div>
      <!-- Виджет Dream Job -->
      <div class="dj-widget">
        <a
          href="https://dreamjob.ru/employers/25954"
          target="_blank"
          rel="noopener"
        >
          <img
            data-id="wg-25954-1-1"
            width="170"
            height="114"
            title="Рейтинг работодателя на Dream Job"
            :src="widgetSrc"
            alt="Перекрёсток отзывы сотрудников о работодателе на Dream Job"
            style="border: 0"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HotVacanciesCommon",
  data() {
    return {
      widgetSrc:
        "image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E",
    };
  },
  mounted() {
    this.widgetSrc = `https://dreamjob.ru/uploads/widgets/wg-25954-1-1.svg?${~~(
      Date.now() / 864e5
    )}`;
  },
};
</script>

<style scoped>
/* Стили для intro-section */
.intro-section {
  background-color: #55c350;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin-top: 20px;
  position: relative;
  text-align: left;
  width: 900px;
  margin: 50px auto;
  font-family: "Gilroy", sans-serif;
  font-weight: normal;
}

.dots {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dots::before,
.dots::after {
  content: "";
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.intro-section p {
  font-size: 1em;
  font-family: "Gilroy", sans-serif;
  font-weight: normal;
}

/* Стили для блока benefits-icons */
.benefits-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 1000px;
  margin: 70px auto;
}

.benefit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1em;
  width: 200px;
  font-weight: normal;
}

.benefit-item img {
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
}

/* Блок с логотипом и виджетом */
.footer-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 900px;
  margin: 30px auto;
}

.logo-containers {
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 500px;
}

.logo-containers img {
  width: 100%;
  max-width: 527px;
  height: auto;
}

.dj-widget {
  flex: 0 0 auto;
  padding-left: 20px;
}

/* Адаптация под мобильные устройства */
@media (max-width: 768px) {
  .intro-section {
    width: 100% !important;
    padding: 10px !important;
    font-size: 0.9em !important;
    margin: 10px auto !important;
    box-sizing: border-box !important;
  }

  .intro-section p {
    font-size: 1em !important;
    margin: 5px 0 !important;
  }

  .benefits-icons {
    flex-direction: column !important;
    align-items: center !important;
    width: 100% !important;
    padding: 0 10px !important;
    box-sizing: border-box !important;
  }

  .benefit-item {
    width: 100% !important;
    margin-bottom: 15px !important;
    text-align: center !important;
  }

  .benefit-item img {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 5px !important;
  }

  .footer-section {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .logo-containers {
    width: 100%;
    justify-content: center;
    margin: 0px auto;
  }

  .logo-containers img {
    width: 100%;
    max-width: 527px;
    height: auto;
    margin: 0px auto;
  }

  .dj-widget {
    padding-left: 0;
    margin-top: 20px;
  }
}
</style>
