<template>
  <div>
    <div class="header-bar">
      <a href="https://rabota.perekrestok.ru">
        <img
          src="https://rabota.perekrestok.ru/Logo_X5_Perek_white.png"
          alt="Логотип Перекрестка"
          class="header-logo"
        />
      </a>
    </div>
    <div>
      <router-view :query="query" />
      <hot-vacancies-common :query="query" />
    </div>
  </div>
</template>

<script>
import HotVacanciesCommon from "@/components/HotVacanciesCommon.vue";

export default {
  name: "hot-vacancies-layout",
  components: {
    "hot-vacancies-common": HotVacanciesCommon,
  },
  data() {
    return {
      query: {},
    };
  },
  created() {
    this.params = window.location.search
      .replace("?", "")
      .split("&")
      .reduce(function (p, e) {
        const a = e.split("=");
        p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
        return p;
      }, this.query);
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Gilroy:wght@700&display=swap");

.form-container .dots {
  position: absolute;
  top: 10px;
  right: 20px;
}

* {
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
}
.content-container {
  max-width: 1200px;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.bg-logo-container {
  width: 175px;
  height: 90px;
  position: relative;
}

.bg-white {
  background-color: #fff;
}

.bg-logo {
  width: 100%;
  position: absolute;
  top: -40px;
  opacity: 0.1;
}

.header-bar {
  background-color: #55c350;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 200px;
}

.header-logo {
  width: 350px;
  margin: 0px 250px;
}

.header-text,
.header-text2 {
  text-align: left;
  font-size: 2em;
  color: #000;
  margin: 20px 500px;
}

.header-text2 {
}

/* Вакансии */
.vacancy-sections {
  display: grid;
  grid-template-columns: 400px 320px 160px;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
}

.vacancy-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.vacancy-box {
  position: relative;
  padding: 0px 0px;
  border-radius: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.vacancy-title {
  font-size: 1.2em;
  color: white;
  margin-bottom: 10px;
  padding-left: 10px;
  text-decoration: none;
}

.icon {
  margin: 10px auto;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 20px;
  left: 100px;
}

.icon-small {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 50px;
  height: 50px;
}

.vacancy-btn {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 0 15px 15px 0;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.vacancy-btn:hover {
  background-color: #ddd;
}

.vacancy-btn:active {
  background-color: #bbb;
}

.form-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #55c350;
  border-radius: 20px;
  width: 900px;
  margin: auto;
  position: relative;
  margin-top: 20px;
}

.steps-section {
  flex: 1;
  padding: 20px;
}

.steps-section h2 {
  color: white;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.step-box {
  background-color: white;
  padding: 35px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.step-content {
  display: flex;
  align-items: center;
}

.step-content img {
  width: 30%;
  margin-right: 40px;
}

.step-content p {
  font-size: 1.2em;
  color: black;
}

.dots {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dots::before,
.dots::after {
  content: "";
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.dotsGreen {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dotsGreen::before,
.dotsGreen::after {
  content: "";
  display: inline-block;
  background-color: #55c350;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.form-section {
  flex: 1;
  background-color: #55c350;
  padding: 20px;
  border-radius: 20px;
  border: 3px solid black;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

.form-section h2 {
  font-size: 1.5em;
  color: white;
  margin-left: 30px;
  margin-top: 10px;
}

.form-section .subtitle {
  font-size: 0.8em;
  color: white;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-top: -18px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-size: 1em;
  color: white;
  margin-bottom: 5px;
  margin-left: 30px;
  font-weight: normal;
}

.form-group input,
.form-group select {
  width: 80%;
  padding: 10px;
  border: 1px solid #2bb24c;
  border-radius: 10px;
  color: black;
  margin-left: 30px;
}

.submit-btn {
  width: 100%;
  padding: 15px;
  background-color: white;
  color: black;
  border: 1px solid #2bb24c;
  border-radius: 40px;
  font-size: 1.2em;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #f0f0f0;
}

/* Стили для чекбокса */
.checkbox-group {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.checkbox-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 0px;
  cursor: pointer;
  position: relative;
}

.checkbox-group label {
  color: white;
  cursor: pointer;
}

.checkbox-group a {
  color: white;
  text-decoration: none;
}
.terms-text {
  font-size: 12px !important; /* Принудительное изменение размера текста */
  color: white;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-top: -10px;
  font-weight: normal;
}
.intro-section {
  background-color: #55c350;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin-top: 20px;
  position: relative;
  text-align: left;
  width: 900px; /* Устанавливаем такую же ширину, как у других блоков */
  margin: 50px auto; /* Центрируем блок */
}

.intro-section .dots {
  position: absolute;
  top: 10px;
  right: 10px;
}

.intro-section p {
  font-size: 1em;
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
}

.benefits-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 900px; /* Устанавливаем такую же ширину, как у других блоков */
  margin: 70px auto; /* Центрируем блок */
}

.benefit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1em;
  width: 100px;
}

.benefit-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.benefit-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
/* Адаптация под мобильные устройства */
@media (max-width: 768px) {
  .header-bar {
    margin: 0 0px;
    padding: 10px;
  }

  .header-logo {
    width: 100%;
    max-width: 527px;
    height: auto;
    margin: 0 auto;
  }

  .header-text,
  .header-text2 {
    font-size: 1.5em;
    margin: 10px auto;
    text-align: center;
  }

  /* Адаптация под мобильные устройства */
  @media (max-width: 768px) {
    .header-bar {
      margin: 0 0px;
      padding: 10px;
    }


    .header-logo {
      width: 100%;
      max-width: 527px;
      height: auto;
      margin: 0 auto;
    }

    .header-text,
    .header-text2 {
      font-size: 1.5em;
      margin: 10px auto;
      text-align: center;
    }

    /* Уменьшаем intro-section на мобильных */
    .intro-section {
      width: 100% !important;
      padding: 10px !important;
      font-size: 0.9em !important;
      margin: 10px auto !important;
      box-sizing: border-box !important;
    }

    .intro-section p {
      font-size: 1em !important;
      margin: 20px 0 !important;
    }

    /* Адаптация вакансий для мобильной версии */
    .vacancy-sections {
      display: flex !important;
      flex-direction: column !important;
      gap: 10px !important;
      width: 100% !important;
      padding: 0 !important;
      box-sizing: border-box !important;
      align-items: center !important;
    }

    .vacancy-column {
      width: 100% !important;
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      gap: 10px !important;
    }

    /* Фиксированная ширина и высота для всех .vacancy-box, убираем изображения */
    .vacancy-box {
      width: 320px !important;
      height: 130px !important;
      padding: 15px !important;
      border-radius: 10px !important;
      box-sizing: border-box !important;
      text-align: center !important;
      margin: 0 auto !important;
      position: relative !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between !important; /* Кнопка будет всегда внизу */
    }

    /* Убираем изображения */
    .vacancy-box .icon,
    .vacancy-box .icon-small {
      display: none !important;
    }

    .vacancy-title {
      font-size: 1em !important;
      padding-left: 10px !important;
      margin-bottom: auto !important; /* Заголовок будет сверху */
    }

    /* Унифицированные стили для кнопки "откликнуться" */
    .vacancy-btn {
      width: 90% !important;
      margin: 0 auto 10px auto !important;
      padding: 10px !important;
      background-color: white !important;
      color: black !important;
      font-weight: bold !important;
      border: 1px solid #2bb24c !important;
      border-radius: 8px !important;
      cursor: pointer !important;
    }

    .vacancy-btn:hover {
      background-color: #ddd !important;
    }

    .vacancy-btn:active {
      background-color: #bbb !important;
    }

    /* Адаптация формы и шагов */
    .form-container {
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
      padding: 0 10px !important;
      box-sizing: border-box !important;
    }

    /* Перенос form-section под steps-section */
    .steps-section,
    .form-section {
      width: 100% !important;
      margin: 10px auto !important;
      padding: 15px !important;
      box-sizing: border-box !important;
    }

    .steps-section h2,
    .form-section h2 {
      font-size: 1.5em !important;
      text-align: left !important;
    }

    .step-box {
      flex-direction: column !important;
      align-items: center !important;
    }

    .step-box {
      background-color: white;
      border-radius: 20px;
      padding: 0px !important;
      position: relative;
      margin-bottom: 20px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    }

    .step-content img {
      width: 40px !important;
      height: 40px !important;
    }

    .step-content p {
      font-size: 1em !important;
      text-align: left !important;
    }

    /* Стили для блока преимуществ */
    .benefits-icons {
      flex-direction: column !important;
      align-items: center !important;
      width: 100% !important;
      padding: 0 10px !important;
      box-sizing: border-box !important;
    }

    .benefit-item {
      width: 100% !important;
      margin-bottom: 15px !important;
      text-align: center !important;
    }

    .benefit-item img {
      width: 40px !important;
      height: 40px !important;
      margin-bottom: 5px !important;
    }
  }
}
</style>
